import { match } from 'ts-pattern'
import { ApolloClient } from '@apollo/client'
import _ from 'lodash'
import {
  UserBasketSettingsLocationFragment,
  GmServiceLocationInput,
  Address,
  LookupAddressQuery,
  LookupAddressQueryVariables,
  LookupAddressDocument,
  CountryCode,
} from '@/generated/graphql'
import { MenuVendor } from '../domain/menu-vendor'
import countriesData from '@/generated/countries.json'
import cityData from '@/generated/cities.json'

export const MIN_QUALITY = 0.4 // Random number between 0 and 1 for a "good" address
export const countries = [
  CountryCode.Gb,
  CountryCode.Ie,
  CountryCode.Nl,
  CountryCode.De,
  CountryCode.Au,
  CountryCode.Nz,
  CountryCode.Sg,
]
export const isValidCity = (city: string) =>
  cityData.find((cityOpt) => cityOpt.name.toUpperCase() === city.toUpperCase())?.name

export const groupMinutesWithVendorLocationIds = (vendorLocations: MenuVendor['locations']) =>
  vendorLocations.reduce((acc: { [key: number]: number[] }, location) => {
    if (!location || location === null) return acc
    if (!location.details.isGMActive) return acc
    const maxMinutes = location.details.gmMaxDistanceMinutes || -1
    const listOfIds = acc[maxMinutes] || []
    return { ...acc, [maxMinutes]: [...listOfIds, parseInt(location.id, 10)] }
  }, {})

export const lookupAddressDetails = async (
  address: Pick<Address, 'street' | 'postcode' | 'city'>,
  apolloClient: ApolloClient<object>,
  useGoogle: boolean,
  locale?: string,
): Promise<Address | null> => {
  const query = [address.street, address.city, address.postcode].filter((s) => s).join(', ')
  const response = await apolloClient
    .query<LookupAddressQuery, LookupAddressQueryVariables>({
      query: LookupAddressDocument,
      variables: { query, countries, useGoogle, locale: locale || null },
    })
    .catch(null)
  if (
    response?.data.lookupAddress?.locations.length &&
    response.data.lookupAddress.locations[0].relevance > MIN_QUALITY
  )
    return response.data.lookupAddress.locations[0].address
  return null
}

export const basketLocationToInputFormat = (
  location: UserBasketSettingsLocationFragment,
): GmServiceLocationInput =>
  match(location)
    // TODO: change eslint rule
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .with({ __typename: 'Address' }, ({ __typename, ...address }) => ({
      address: {
        flatOrBuilding: address.flatOrBuilding,
        floorOrBuildingName: address.floorOrBuildingName,
        city: address.city,
        country: address.country,
        postcode: address.postcode,
        street: address.street,
        latitude: address.latitude,
        longitude: address.longitude,
      },
    }))
    .with(
      { __typename: 'NewAccountLocation' },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ label, address: { __typename, ...restAddress } }) => ({
        address: {
          label,
          flatOrBuilding: restAddress.flatOrBuilding,
          floorOrBuildingName: restAddress.floorOrBuildingName,
          city: restAddress.city,
          country: restAddress.country,
          postcode: restAddress.postcode,
          street: restAddress.street,
          latitude: restAddress.latitude,
          longitude: restAddress.longitude,
        },
      }),
    )
    .with({ __typename: 'AccountLocation' }, ({ id }) => ({
      accountLocation: { id: Number(id) },
    }))
    .exhaustive()

export const countryFriendlyName = (country?: string | null): string =>
  !country
    ? ''
    : countriesData.find((countryOpt) => countryOpt.code === country.toUpperCase())?.name || country

const addressToLines = (address: Address & { label?: string | null }): string[] =>
  _.compact<string>([
    address.label,
    address.flatOrBuilding,
    address.floorOrBuildingName,
    address.street,
    address.city,
    address.postcode,
    countryFriendlyName(address.country),
  ])
export const addressToSingleLine = (address: Address & { label?: string | null }) =>
  addressToLines(address).join(', ')
export const addressToLabelAndDetailLines = (address: Address & { index?: number }) => {
  const lines = addressToLines(address)
  if (lines.length === 0) return { label: '', details: '', index: address.index }
  const [label, ...rest] = addressToLines(address)
  return { label, details: rest.join(', '), index: address.index }
}

export const isSameLocation = (
  orderDetailsLocation: UserBasketSettingsLocationFragment,
  basketLocation: UserBasketSettingsLocationFragment,
) => {
  const { __typename: detailsType } = orderDetailsLocation
  const { __typename: basketType } = basketLocation

  if (detailsType === 'NewAccountLocation' && basketType === 'NewAccountLocation') {
    const detailsLines = addressToSingleLine(orderDetailsLocation.address)
    const basketLines = addressToSingleLine(basketLocation.address)

    return detailsLines === basketLines
  }
  if (detailsType === 'Address' && basketType === 'Address') {
    const detailsLines = addressToSingleLine({ ...orderDetailsLocation, label: null })
    const basketLines = addressToSingleLine({ ...basketLocation, label: null })

    return detailsLines === basketLines
  }
  if (detailsType === 'AccountLocation' && basketType === 'AccountLocation') {
    return orderDetailsLocation.id === basketLocation.id
  }
  return false
}
